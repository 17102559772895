const brandColors = {
  primary: '#26B4DB',
  secondary: '#2F373B',
};

const sidebarColors = {
  backgroundColor: '#E0F0F4',
  color: '#2F373B',
  dotfileLogo: '#2F373B',
};

const linkColors = {
  color: '#0176FF',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
